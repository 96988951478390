<template>
  <div>
    <h4>카테고리 리스트</h4>

    <el-button type="primary" @click="openAddCategoryDialog">카테고리 추가</el-button>

    <draggable
      :list="categories"
      item-key="id"
      ghost-class="ghost"
      tag="transition-group"
    >
      <template #item="{ element }">
        <el-card>
          {{ element.name }}
          <el-button size="mini" @click="deleteCategory(element)">삭제</el-button>
        </el-card>
      </template>
    </draggable>

    <el-button type="primary" @click="saveCategoriesOrder">저장</el-button>

    <el-dialog v-model="addCategoryDialogVisible" title="카테고리 추가" width="50%">
      <div>
        <el-input v-model="categoryName" placeholder="카테고리 이름" />
        <el-button type="primary" size="mini" @click="createCategory">추가</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import CategoryService from "../services/CategoryService";

export default {
  components: {
    draggable
  },
  data() {
    return {
      categories: [],
      addCategoryDialogVisible: false,
      categoryName: "",
    };
  },
  methods: {
    async retrieveCategories() {
      try {
        const resp = await CategoryService.getAll();
        this.categories = resp.data;
        console.log("categories: ", this.categories);
      } catch (e) {
        console.log(e);
      }
    },
    openAddCategoryDialog() {
      this.categoryName = "";
      this.addCategoryDialogVisible = true;
    },
    async createCategory() {
      if (!this.categoryName) {
        this.$message.error(`카테고리의 이름을 입력해주세요.`);
        return;
      }
      try {
        const resp = await CategoryService.create({
          name: this.categoryName,
        });
        console.log("category: ", resp.data);
        this.$message.success(
          "카테고리가 추가되었습니다."
        );
        this.addCategoryDialogVisible = false;
        this.retrieveCategories();
      } catch (e) {
        console.log(e);
      }
    },
    async saveCategoriesOrder() {
      try {
        await CategoryService.saveCategoriesOrder({
          categories: this.categories,
        });
        this.$message.success(
          "카테고리들의 순서가 저장되었습니다."
        );
      } catch (e) {
        console.log(e);
      }
    },
    async deleteCategory(category) {
      try {
        await this.$confirm(`정말로 ${category.name} 카테고리를 삭제하시겠습니까?`);
        await CategoryService.delete(category.id);
        this.$message.success(`${category.name} 카테고리가 삭제되었습니다.`);
        this.retrieveCategories();
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.retrieveCategories();
  },
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>