import http from '../http-common';

class CategoryService {
  create(data) {
    return http.post('/category/create', data);
  }

  getAll() {
    return http.get('/category/getAll');
  }

  delete(id) {
    return http.delete(`/category/delete/${id}`);
  }

  saveCategoriesOrder(data) {
    return http.post('/category/saveCategoriesOrder', data);
  }
}

export default new CategoryService();
